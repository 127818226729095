.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-finished {
    opacity: 0 !important;
    transition: opacity 1.5s ease;
    pointer-events: none;
}

.markdown-line {
    margin-bottom: 2px; /* Adjust this value as needed */
}

.markdown-line p {
    margin: 0px; /* This will remove the margin from all <p> elements inside .markdown-line */
}

.markdown-title {
    margin: 0px; /* This will remove the margin from all <p> elements inside .markdown-line */
}

.markdown-title p {
    margin: 0px; /* This will remove the margin from all <p> elements inside .markdown-line */
}

blockquote {
    margin: 0px 5px 0px 5px;
}