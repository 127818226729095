html,
body {
    margin: 0;
    padding: 0;
    height: 100dvh;
    width: 100dvw;
    font-family: 'Roboto';
    overflow-x: hidden;
}

.browser-window-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    width: 100%;
    --select-container-height: 0px;
}
