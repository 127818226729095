*,
*::before,
*::after {
    box-sizing: border-box; /* Ensures padding and border are included in the width/height */
}

.top-container {
    padding-top: 12px;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-container {
    flex-grow: 1;
    min-width: 50%;
}

.dark-mode-container {
    flex-shrink: 0;
    max-width: 50%;
    margin-left: 20px;
}

.chart-container {
    flex-grow: 1;
    height: 100%;
}

.customInputLabel {
    color: "primary.main";
}
